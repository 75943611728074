.play-button {
  margin: 0 auto;
  margin-left: -1.2em;
  top: 25%;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.5em 0 0.5em 1em;
  border-color: transparent transparent transparent green;
  opacity: 0.75;
}
