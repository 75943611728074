:root {
  --lightestgrey: #e4e4e4;
  --lightgrey: #a8a8a8;
  --grey: #5a5a5a;
}

body {
  padding: 0vh;
  margin: 0vh;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

/* Scrollbar main */
::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  opacity: 0.0;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: var(--lightgrey);
  border-radius: 10px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--grey);
}

/* Scrollbar Corner */
::-webkit-scrollbar-corner {
  opacity: 0.0;
}
