/* unset the foreground colors so that the text color is inherited from the parent so that dark/light mode is applied correctly*/
.vis-time-axis .vis-text {
  color: unset;
}

.vis-labelset .vis-label {
  color: unset;
}

/* Make this tooltip invisible */
.vis-item .vis-onUpdateTime-tooltip {
  padding: 0px;
}

/* Box item styling */
.vis-item.vis-box {
  border-radius: 6px;
}

.vis-item.vis-range {
  border-radius: 6px;
}

.vis-item.vis-cluster {
  border-radius: 6px;
}

/* Text styling */
.vis-text {
  font-weight: 600;
}

/* Cluster styling */
.vis-cluster {
  min-width: 170px;
  overflow-x: hidden;
}

.cluster-header {
  display: block;
  font-weight: 600;
}
