/* Page */
.sharePointPageTitle {
  background-image: url('/public/images/sharepoint-page-background.png')
}

.sharePointPageTitleDark {
  background-image: url('/public/images/sharepoint-page-background-dark.png')
}

.sharePointPage {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.sharePointSection {
  font-size: 16px;
}

/* Titles */
h1 {
  font-size: 28px;
  font-weight: 600;
}

h2 {
  font-size: 24px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

h4 {
  font-size: 16px;
  font-weight: 600;
}

/* Links */
a {
  color: #64a4d4;
  text-decoration: underline;
}

a :hover {
  color: #3484c2;
  text-decoration: underline;
}

/* Special text styles */
pre {
  background-color: rgb(250, 249, 248);
}

blockquote {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: lightgrey;
  border-left: none;
  border-right: none;
  padding: 32px;
  text-align: center;
  font-style: italic;
}

blockquote p {
  font-weight: 600;
  margin: 0
}

/* Font Colors */
.fontColorGreen {
  color: #107c10
}

.fontColorRedDark {
  color: #a80000
}

.fontColorRed {
  color: #e81123
}

.fontColorYellow {
  color: #ffb900
}

.fontColorYellowLight {
  color: #fff100
}

.fontColorGreenLight {
  color: #bad80a
}

.fontColorGreen {
  color: #107c10
}

.fontColorBlueLight {
  color: #00bcf2
}

.fontColorBlue {
  color: #0078d4
}

.fontColorBlueDark {
  color: #002050
}

.fontColorPurple {
  color: #5c2d91
}

.fontColorNeutralDark {
  color: #212121
}

.fontColorNeutralPrimary {
  color: #333333
}

.fontColorNeutralPrimaryAlt {
  color: #3c3c3c
}

.fontColorNeutralSecondary {
  color: #666666
}

.fontColorNeutralTertiary {
  color: #a6a6a6
}

.fontColorThemeDarker {
  color: #004578
}

.fontColorThemeDark {
  color: #005a9e
}

.fontColorThemeDarkAlt {
  color: #106ebe
}

.fontColorThemePrimary {
  color: #0078d4
}

.fontColorThemeSecondary {
  color: #2b88d8
}

/* Font size */
.fontSizeMediumPlus {
  font-size: 15px
}

.fontSizeLarge {
  font-size: 17px
}

.fontSizeXLarge {
  font-size: 21px
}

/* Highlight colors */
.highlightColorYellow {
  background-color: #ff0
}

.highlightColorGreen {
  background-color: #0f0
}

.highlightColorAqua {
  background-color: #0ff
}

.highlightColorMagenta {
  background-color: #f0f
}

.highlightColorBlue {
  background-color: #00f
}

.highlightColorRed {
  background-color: red
}

.highlightColorDarkBlue {
  background-color: navy
}

.highlightColorTeal {
  background-color: teal
}

.highlightColorDarkGreen {
  background-color: green
}

.highlightColorPurple {
  background-color: purple
}

.highlightColorMaroon {
  background-color: maroon
}

.highlightColorGold {
  background-color: olive
}

.highlightColorDarkGrey {
  background-color: grey
}

.highlightColorGrey {
  background-color: silver
}

.highlightColorBlack {
  background-color: #000
}

/* Tables */
.canvasRteResponsiveTable table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid;
  border-color: grey;
  padding: 5px;
  overflow-x: auto;
  clear: both;
  text-align: left;
}

.canvasRteResponsiveTable td {
  border-collapse: collapse;
  border: 1px solid;
  border-color: grey;
  padding: 5px;
}

.canvasRteResponsiveTable th {
  border-collapse: collapse;
  border: 1px solid;
  border-color: grey;
  padding: 5px;
}

/* Border table header style Neutral*/
.borderHeaderTableStyleNeutral th {
  border-bottom-width: 3px !important;
  border-color: grey !important;
}

/* Simple table header style */
.simpleTableStyleTheme td {
  border-color: rgb(3, 120, 124) !important;
}

/* Border table header style Theme*/
.borderHeaderTableStyleTheme th {
  border-color: rgb(3, 120, 124) !important;
  border-bottom-width: 3px !important;
}

.borderHeaderTableStyleTheme td {
  border-color: rgb(3, 120, 124) !important;
}

/* Filled table header style */
.filledHeaderTableStyleNeutral th {
  background-color: #181a1b;
  color: white;
  font-weight: bold;
}

.filledHeaderTableStyleTheme tr:first-child {
  background-color: #c7e0f4;
  font-weight: bold;
}

/* 
// Banded table styles 
*/

/* bandedRowTableStyleNeutral */
.bandedRowTableStyleNeutral thead {
  background-color: #181a1b;
  color: white;
  font-weight: 600;
}

.bandedRowTableStyleNeutral tr:nth-child(2n) {
  background-color: #dadada;
  color: black;
}

/* bandedRowColumnTableStyleNeutral */
.bandedRowColumnTableStyleNeutral thead {
  background-color: #181a1b;
  color: white;
  font-weight: 600;
}

.bandedRowColumnTableStyleNeutral tr:nth-child(2n) {
  background-color: #dadada
}

.bandedRowColumnTableStyleNeutral thead+tbody th:first-child {
  background-color: #c8c8c8;
  color: black;
  font-weight: 600
}

/* bandedRowTableStyleTheme */
.bandedRowTableStyleTheme thead {
  background-color: rgb(3, 120, 124);
  color: white;
  font-weight: 600;
}

.bandedRowTableStyleTheme tr:nth-child(2n) {
  background-color: #c5e9ea;
  color: black;
}

/* bandedRowColumnTableStyleTheme */
.bandedRowColumnTableStyleTheme thead {
  background-color: rgb(3, 120, 124);
  color: white;
  font-weight: 600;
}

.bandedRowColumnTableStyleTheme tr:nth-child(2n) {
  background-color: #c5e9ea
}

.bandedRowColumnTableStyleTheme thead+tbody th:first-child {
  background-color: #98d6d8;
  color: black;
  font-weight: 600
}

/* Table alignment */
.tableLeftAlign {
  margin-left: 0;
  margin-right: auto
}

.tableCenterAlign {
  margin-left: auto;
  margin-right: auto;
}

.tableRightAlign {
  margin-left: auto;
  margin-right: 0;
}